import { createTheme } from "@mui/material/styles";

// Temas en Solicitud en src/config/theme.js
// Temas en BOA / BOU en src/@fuse/default-settings/FuseDefaultSettings en func: mainThemeVariations
const TIA_MARTA = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#936ba9",
      unload: "",
      iconColor: "#936ba9",
      checkColor: "#936ba9",
      numColor: "#ffffff",
      completedColor: "#ffffff",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#936ba9" },
    secondary: {
      main: "#eee6fd",
    },
    background: {
      default: "#eee6fd",
    },
    button: {
      main: "#1c1b1b",
      light: "#1c1b1b30",
      light2: "#1c1b1b60",
    },
    text: {
      main: "#000000",
      contrast: "#000000",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "purple",
});

const FINMUTUAL = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#93c572",
      iconColor: "#93c572",
      checkColor: "#000000",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#C4D89D",
    },
    highlight: { main: "#93c572" },
    secondary: {
      main: "#c4d89d",
    },
    background: {
      default: "#f5f6f8",
    },
    button: {
      // main: "#93c572",
      main: "#6527BE",
      light: "#1c1b1b30",
      light2: "#1c1b1b60",
    },
    text: {
      main: "#000000",
      contrast: "#FFFFFF",
    },
    spinner: "#000000",
    spinnerBackground: "#93c572",
  },
  banner: true,
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "#93c572",
});

const CREDIV = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#27448C",
      iconColor: "#27448C",
      checkColor: "#000000",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#ab576d",
    },
    highlight: { main: "#27448C" },
    secondary: {
      main: "#ab576d",
    },
    background: {
      default: "#f5f6f8",
    },
    button: {
      main: "#1c1b1b",
    },
    text: {
      main: "#000000",
      contrast: "#27448C",
    },
    spinner: "#ab576d",
    spinnerBackground: "#f5f6f8",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "#ab576d",
});

const DEMO = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0FD08B",
      iconColor: "#0FD08B",
      checkColor: "#0FD08B",
      numColor: "#2A1846",
      completedColor: "#2A1846",
      backgroundStepColor: "#0FD08B",
    },
    highlight: { main: "#2A1846" },
    secondary: {
      main: "#2A1846",
    },
    background: {
      default: "#F8F8F6",
    },
    button: {
      main: "#1c1b1b",
      light: "#1c1b1b30",
      light2: "#1c1b1b60",
    },
    text: {
      main: "#000000",
      contrast: "#000000",
    },
    spinner: "#000000",
    spinnerBackground: "#0FD08B",
  },
  banner: true,
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "",
});

const TEMPLATE = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#3159c1",
      iconColor: "#3159c1",
      checkColor: "#3159c1",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#3159c1" },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#EAEAEA",
    },
    button: {
      main: "#3159c1",
      light: "#3159c130",
      light2: "#3159c160",
    },
    text: {
      main: "#000000",
      contrast: "#333333",
    },
    spinner: "#ffffff",
    spinnerBackground: "#3159c1",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "",
});

const MAYEUTIC = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#8EE48E",
      iconColor: "#8EE48E",
      checkColor: "#8EE48E",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#8EE48E" },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    button: {
      main: "#8EE48E",
      light: "#8EE48E30",
      light2: "#8EE48E60",
    },
    text: {
      main: "#000000",
      contrast: "#191970",
    },
    spinner: "#191970",
    spinnerBackground: "#8EE48E",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "green",
});

const CAIXA = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0071B1",
      iconColor: "#0071B1",
      checkColor: "#0071B1",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#0071B1" },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    button: {
      main: "#0071B1",
      light: "#0071B1",
      light2: "#0071B1",
    },
    text: {
      main: "#000000",
      contrast: "#F68326",
    },
    spinner: "#F68326",
    spinnerBackground: "#0071B1",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  // banner: true,
  multiDateColor: "blue",
});

const VINAN = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
          color: "#FFFFFF",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1b71b4",
      iconColor: "#1b71b4",
      checkColor: "#1b71b4",
      numColor: "#000000",
      completedColor: "#000000",
      backgroundStepColor: "#f5ab00",
    },
    highlight: { main: "#1b71b4" },
    secondary: {
      main: "#000000",
    },
    background: {
      default: "#ffffff",
    },
    button: {
      main: "#1b71b4",
      light: "#f5ab00",
      light2: "#f5ab00",
    },
    text: {
      main: "#000000",
      contrast: "#1b71b4",
    },
    spinner: "#f5ab00",
    spinnerBackground: "#ffffff",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "#7c74fc",
});

const DEFAULT = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
  },
  palette: {
    background: {
      default: "#eee6fd",
      iconColor: "#1f1f1f",
      checkColor: "#1f1f1f",
      numColor: "#ffffff",
      completedColor: "#000000",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#eee6fd" },
    primary: {
      main: "#eee6fd",
    },
    secondary: {
      main: "#DAB0EA",
    },
    button: {
      main: "#3159c1",
      light: "#3159c130",
      light2: "#3159c160",
    },
    text: {
      main: "#1f1f1f",
      contrast: "#1f1f1f",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "",
});

const CARSCORE = createTheme({
  components: {
    MuiCollapse: {
      styleOverrides: {
        root: {
          transition: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          backgroundColor:"#EBEBEB"
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          textTransform: "uppercase",
          boxShadow:"none !important",
          color: "#FFFFFF",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          // minHeight: "100%",
          height:"5.5rem"
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          backgroundColor:"#f2f2f2"
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 0px 0px",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#343330",
      iconColor: "#343330",
      checkColor: "#343330",
      numColor: "#1c1c1c",
      completedColor: "#1c1c1c",
      backgroundStepColor: "#ccc",
    },
    highlight: { main: "#343330" },
    secondary: {
      main: "#1c1c1c",
    },
    background: {
      default: "#ffffff",
    },
    button: {
      main: "#df4524",
      light: "#343330",
      light2: "#343330",
    },
    text: {
      main: "#1c1c1c",
      contrast: "#1c1c1c",
    },
    spinner: "#df4524",
    spinnerBackground: "#343330",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  multiDateColor: "green",
});

export {
  CAIXA,
  CREDIV,
  DEFAULT,
  DEMO,
  FINMUTUAL,
  MAYEUTIC,
  TEMPLATE,
  TIA_MARTA,
  VINAN,
  CARSCORE
};
