import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "../Core.css";

export default function CoreCarScore() {
  const carScoring = useSelector((state) => state.config.carScoring);
  const theme = useTheme();
  const [usage, setUsage] = React.useState(carScoring.toUse);
  React.useEffect(() => {
    setUsage(carScoring.toUse);
  }, [carScoring.toUse]);
  return (
    <Grid item>
      <Typography
        align="right"
        sx={{
          fontSize: "15px",
          color: theme.palette.button.main,
        }}
      >
        Usos restantes:
      </Typography>
      <Typography
        align="right"
        sx={{
          fontSize: "15px",
        }}
      >
        {usage}
      </Typography>
    </Grid>
  );
}
