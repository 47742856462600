import { get,post } from ".";

const carScoringAPI = {
  getCarScoreParams: (token) =>
    get(`car-score/get-all`,token),
  getCarScoreUtils: (token) =>
    get(`car-score/utils`,token),
  getCarScoreUsages: (token) =>
    get(`car-score/total-usages`,token),
  addCarScoreUsage: (token) =>
    post(`car-score/add-usage`,token,null),
};

export default carScoringAPI;
