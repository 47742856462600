import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import Error from '../../../Warnings/Error';
import { useScoringB, useScreenShot } from './hooks';
import { currencyFormat, currencyFormatFixed } from '../../../../utils';
import { useDispatch } from 'react-redux';

const StepScoringB = ({ handleBackStep }) => {
  const { data, paramsPlazos, errorText, openAlert, setOpenAlert, carScoring } =
    useScoringB();
  const dispatch = useDispatch();
  const { generarPDF } = useScreenShot();
  const theme = useTheme();
  const ofertable = carScoring && carScoring.score <= 400;
  return (
    <Box>
      <Box
        id="tablaFinanciamiento"
        display="flex"
        flexDirection="column"
        // gap={4}
      >
        <Grid container spacing={1}>
          <Grid item xs={ofertable ? 6 : 12}>
            <TableContainer component={Paper}>
              <Table aria-label="financing table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 600 }}>
                      Cliente con posible oferta de Financiación:{' '}
                      <strong
                        style={{
                          color: theme.palette.button.main,
                          fontSize: 18,
                        }}
                      >
                        {ofertable ? 'SI' : 'NO'}
                      </strong>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {ofertable && data && (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Valor Auto USD
                      </TableCell>
                      <TableCell>
                        {currencyFormatFixed(data.valorUSD, 0)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        % a financiar
                      </TableCell>
                      <TableCell>
                        {currencyFormatFixed(data.toFinancePercent, 0)}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Monto a financiar USD
                      </TableCell>
                      <TableCell>
                        {currencyFormatFixed(
                          Math.round(data.toFinanceMontoUSD / 100) * 100,
                          0
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Monto a financiar Equivalente en $
                      </TableCell>
                      <TableCell>
                        {currencyFormatFixed(data.toFinanceMonto, 0)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>
          {ofertable && (
            <Grid item xs={6}>
              <TableContainer component={Paper}>
                <Table aria-label="financing table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Plazos y Cuotas Sugeridas
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>Plazo</TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Importe cuota USD
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }}>
                        Importe cuota $
                      </TableCell>
                    </TableRow>
                    {paramsPlazos &&
                      paramsPlazos.map((item, i) => (
                        <TableRow key={i}>
                          <TableCell>{item.plazo}</TableCell>
                          <TableCell>
                            {currencyFormatFixed(item.importeUSD,-1)}
                          </TableCell>
                          <TableCell>{currencyFormatFixed(item.importe,-1)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>

        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
      </Box>
      {ofertable ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          sx={{ padding: '1rem' }}
        >
          <Button
            variant="outlined"
            color="button"
            // sx={{ color: theme.palette.text.main }}
            onClick={() => {
              handleBackStep();
              dispatch({ type: 'stepper/show' });
            }}
          >
            Hacer otra consulta
          </Button>
          <Button
            color="button"
            variant="contained"
            onClick={() => generarPDF()}
          >
            Guardar en PDF
          </Button>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
          gap={4}
          sx={{ padding: '1rem' }}
        >
          <Button
            variant="outlined"
            color="button"
            // sx={{ color: theme.palette.text.main }}
            onClick={() => {
              handleBackStep();
              dispatch({ type: 'stepper/show' });
            }}
          >
            Hacer otra consulta
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StepScoringB;
