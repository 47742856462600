import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { getSucursales, login } from '../../services/auth.services';
import { errorSetter } from '../../utils';
import { SelectInput, TextInput } from '../Inputs';
import Error from '../Warnings/Error';

const captchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const LoginMinified = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const dataset = useSelector((state) => state.config.dataset);
  React.useEffect(() => {
    if (!dataset.operador) {
      if (activeStep === maxStep) {
        handleMainNextStep();
      } else {
        handleNextStep();
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset]);
  const [openError, setOpenError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [correo, setCorreo] = useState('');
  const [password, setPassword] = useState('');
  const [tyc, setTyc] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: 'show/spinner' });
    if (correo === '' || password === '') {
      dispatch({ type: 'hide/spinner' });
      errorSetter(setOpenError, setErrorText, null);
    }
    getSucursales(correo)
      .then((res) => {
        if (res.data.length === 1) {
          login(correo, password, res.data[0].id)
            .then((response) => {
              dispatch({
                type: 'operador/datos',
                payload: {
                  sucursal: res.data[0],
                  mail: correo,
                  token: response.data
                    ? response.data.auth
                      ? response.data.auth
                      : response.headers.authorization
                    : response.headers.authorization,
                  canal: 'SOLICITUD_WEB',
                },
              });
              dispatch({
                type: 'change/step1',
                payload: 'Ingreso de Datos',
              });
              dispatch({
                type: 'sucursal',
                payload: res.data[0].id,
              });
              dispatch({ type: 'hide/spinner' });
              if (activeStep === maxStep) {
                handleMainNextStep();
              } else {
                handleNextStep();
              }
            })
            .catch((error) => {
              console.log(error);
              dispatch({ type: 'hide/spinner' });
              errorSetter(setOpenError, setErrorText, error);
            });
        } else {
          dispatch({ type: 'hide/spinner' });
          errorSetter(setOpenError, setErrorText, null);
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'hide/spinner' });
      });
  };

  return (
    !loading && (
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={(e) => handleSubmit(e)}
      >
        <FormControl required>
          <TextInput
            id="usuario"
            name="usuario"
            label="Usuario"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
          />
        </FormControl>
        <FormControl required>
          <TextInput
            id="password"
            name="password"
            label="Contraseña"
            type="password"
            autoComplete="current-password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        <FormGroup required>
          <FormControlLabel
            value={tyc}
            checked={tyc}
            onChange={() => setTyc((prev) => !prev)}
            control={
              <Checkbox
                style={{ color: theme.palette.primary.checkColor }}
                id="pyc"
                name="pyc"
                required
              />
            }
            // onClick={handleClickOpen}
            label={
              <Typography fontSize="small" style={{ display: 'inline' }}>
                <a
                  style={{ color: '#000000' }}
                  // href={dataset.urlTerminos}
                  href={`${process.env.PUBLIC_URL}/assets/tyc/scorecar_initial.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Acepto términos y condiciones
                </a>
              </Typography>
            }
          />
        </FormGroup>
        <FormControl required sx={{ width: '100%' }}>
          <Button
            type="submit"
            variant="contained"
            color="button"
            sx={{
              marginTop: '1rem',
              width: 'min-content',
              alignSelf: 'flex-end',
            }}
          >
            Continuar
          </Button>
        </FormControl>
        <Error text={errorText} show={openError} setShow={setOpenError} />
      </Box>
    )
  );
};

export default LoginMinified;
