import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import carScoringAPI from '../../../../services/Api/carScoring';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import moment from 'moment';

const useScoringB = () => {
  const dataset = useSelector((state) => state.config.dataset);
  const carScoring = useSelector((state) => state.config.carScoring);
  const score = useSelector((state) => state.config.carScoring.score);
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = React.useState(false);
  const iva = carScoring.iva / 100;
  const cotizacion = carScoring.cotizacionUSD;
  const [errorText, setErrorText] = React.useState('Ha ocurrido un error');
  const [data, setData] = React.useState({
    toFinanceMonto: 0,
    toFinanceMontoUSD: 0,
    toFinancePercent: 0,
    valorUSD: 0,
  });
  const [paramsPlazos, setParamsPlazos] = React.useState(null);
  const findParams = (params) => {
    const param = params.find(
      (e) => e.scoreMin <= score && e.scoreMax >= score
    );
    let values = {
      valorUSD: 0,
      toFinancePercent: 0,
      toFinanceMontoUSD: 0,
      toFinanceMonto: 0,
      teaUSD: 0,
      tea: 0,
    };
    if (!param || !param.toFinance) {
      setParamsPlazos([]);
      setData(values);
    } else {
      values = {
        valorUSD: carScoring.valorUSD,
        toFinancePercent: param.toFinance * 100,
        toFinanceMontoUSD:
          Math.round((carScoring.valorUSD * param.toFinance) / 100) * 100,
        toFinanceMonto:
          Math.round((carScoring.valorUSD * param.toFinance) / 100) *
          100 *
          cotizacion,
        teaUSD: param.teaUsd,
        tea: param.tea,
      };
      const plazos = param.plazos.map((e) => {
        const obj = {
          importeUSD: calcImporteCuota(
            values.toFinanceMontoUSD,
            values.teaUSD,
            e.plazo
          ),
          importe: calcImporteCuota(values.toFinanceMonto, values.tea, e.plazo),
          plazo: e.plazo,
        };
        return obj;
      });
      setParamsPlazos(plazos);
      setData(values);
    }
  };
  const calcImporteCuota = (monto, tasa, plazo) => {
    const iMensualCIva = ((1 + tasa) ** (1 / 12) - 1) * (1 + iva);
    const montoPlazo =
      monto *
      ((iMensualCIva * (1 + iMensualCIva) ** plazo) /
        ((1 + iMensualCIva) ** plazo - 1));
    return montoPlazo;
  };

  React.useEffect(() => {
    dispatch({ type: 'stepper/hide' });
    dispatch({ type: 'step/car-score/show-legal' });
    dispatch({ type: 'show/spinner' });
    carScoringAPI
      .addCarScoreUsage(dataset.token)
      .then((r) => {
        dispatch({ type: 'step/car-add-usages' });
      })
      .catch((err) => {
        console.log(err);
        setErrorText('Ha ocurrido un error');
        dispatch({
          type: 'show/error',
          payload: {
            errorTitle: 'Sin ofertas',
            errorBody: dataset.sinOfertasText,
          },
        });
        dispatch({ type: 'hide/spinner' });
      });
    carScoringAPI
      .getCarScoreParams(dataset.token)
      .then((r) => {
        findParams(r.data);
        dispatch({ type: 'hide/spinner' });
      })
      .catch((err) => {
        console.log(err);
        setErrorText('Ha ocurrido un error');
        dispatch({
          type: 'show/error',
          payload: {
            errorTitle: 'Sin ofertas',
            errorBody: dataset.sinOfertasText,
          },
        });
        dispatch({ type: 'hide/spinner' });
      });
  }, []);

  return {
    data,
    paramsPlazos,
    errorText,
    openAlert,
    setOpenAlert,
    dataset,
    carScoring,
  };
};

const useScreenShot = () => {
  const generarPDF = async () => {
    const divToPrintRef = document.getElementById('tablaFinanciamiento'); // ID del contenedor que deseas capturar

    // Cargar el PDF plantilla desde la carpeta public
    const pdfUrl = `${process.env.PUBLIC_URL}/Descargable.pdf`;
    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
      res.arrayBuffer()
    );

    // Crear un nuevo documento PDF basado en la plantilla
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Capturar el contenido del div como imagen
    const divElement = divToPrintRef;
    const canvas = await html2canvas(divElement, { scale: 3 });
    const imgData = canvas.toDataURL('image/png');

    // Añadir una nueva página o usar la existente del PDF
    const pages = pdfDoc.getPages();
    const firstPage = pages[0]; // Asumimos que tiene una página

    // Obtener las dimensiones de la página
    const { width, height } = firstPage.getSize();

    const fechaActual = moment().format('DD/MM/YYYY');

    // Añadir la fecha en la parte superior de la página
    firstPage.drawText(`Fecha: ${fechaActual}`, {
      x: 450, // Ajusta la posición horizontal
      y: height - 30, // Ajusta la posición vertical (parte superior de la página)
      size: 12, // Tamaño de la fuente
      color: rgb(0, 0, 0), // Color del texto (negro)
    });

    // Añadir la imagen capturada del div al PDF en las coordenadas que elijas
    const pngImage = await pdfDoc.embedPng(imgData);
    firstPage.drawImage(pngImage, {
      x: 50, // Ajusta las coordenadas 'x' e 'y' para posicionar la imagen
      y: height - 500, // Ajusta 'y' para que quede en la parte blanca
      width: 500, // Ajusta el tamaño de la imagen
      height: 300,
    });

    // Serializar el documento PDF y descargarlo
    const pdfBytes = await pdfDoc.save();

    // Descargar el archivo PDF usando file-saver
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    saveAs(blob, 'custom-pdf.pdf');
  };
  return { generarPDF };
};

export { useScoringB, useScreenShot };
