import credivConf from "../services/reducers/configReducer/config/credivConf";
import finmutualConf from "../services/reducers/configReducer/config/finmutualConf";
import carScoreConf from '../services/reducers/configReducer/config/carScoreConf';
import {
  isDemoFlavour,
  isLocalhost,
  isStaging,
} from "../services/reducers/configReducer/config/rootConf";
import tiamartaConf from "../services/reducers/configReducer/config/tiamartaConf";
import vinanConf from "../services/reducers/configReducer/config/vinanConf";
import {
  CREDIV,
  FINMUTUAL,
  CARSCORE,
  MAYEUTIC,
  CAIXA,
  // MAGNOLIO
  TEMPLATE,
  TIA_MARTA,
  VINAN,
} from "./theme";

let theme;
if (vinanConf.is()) theme = VINAN;
else if (credivConf.is()) theme = CREDIV;
else if (carScoreConf.is()) theme = CARSCORE;
else if (isLocalhost() || (isStaging() && isDemoFlavour())) {
  // theme = DEMO;
  // theme = VINAN;
  // theme = MAYEUTIC;
  theme = CARSCORE
  // theme = CAIXA;
  // theme = FINMUTUAL;
} else if (tiamartaConf.is()) theme = TIA_MARTA;
else if (carScoreConf.is()) theme = CARSCORE;
else if (finmutualConf.is()) theme = FINMUTUAL;
else theme = TEMPLATE;

export { theme };
