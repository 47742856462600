import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { validationStepOneA } from '../../../../config/validationSchemas';
import { formTriesActions } from '../../../../services/reducers/formTriesSlice';
import { stepScoringA } from '../../../../services/steps.services';
import {
  capitalize,
  clearCedula,
  errorSetter,
  showEmotions,
  getSubstractYearsToToday,
  transformarNumeroEspanol,
  validarNumeroEspanol,
} from '../../../../utils';
import { DateInput, TextInput } from '../../../Inputs';
import Error from '../../../Warnings/Error';
import { getIp, getEnum } from '../hooks';
import useGetRegexValidationDocument from '../hooks/useGetRegexValidationDocument';
import useGetRegexValidationPhone from '../hooks/useGetRegexValidationPhone';
import AutoComplete from '../../../Inputs/AutoComplete';
import carScoringAPI from '../../../../services/Api/carScoring';

//import OTP from "../../OTP/OTP";

const errors = {
  codeIsVerifyingAge: 'Solicitud.verify_born_date',
};

const StepScoringA = ({
  handleNextStep,
  handleMainNextStep,
  activeStep,
  maxStep,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const dataset = useSelector((state) => state.config.dataset);

  const minYearsAgo = 15;
  const maxYearsAgo = 100 + minYearsAgo;

  const [openAlert, setOpenAlert] = React.useState(false);
  const [generos, setGeneros] = React.useState([]);
  const [errorText, setErrorText] = React.useState('Ha ocurrido un error');

  const [callValues, setCallValues] = useState(null);
  const [ipData, setIpData] = React.useState(null);
  const [ipDataErr, setIpDataErr] = React.useState(null);
  const regexValidationDocument = useGetRegexValidationDocument();
  const regexValidationPhone = useGetRegexValidationPhone();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => getIp(setIpData, setIpDataErr), []);

  const validation = useFormik({
    initialValues: {
      cedula: '',
      telefono: '',
      pyc: true,
      fechaNacimiento: '',
      genero: '',
      ingresos: '',
      montoAFinanciarUSD: '',
      valorUSD: '',
    },
    validationSchema: validationStepOneA(
      regexValidationDocument,
      regexValidationPhone
    ),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const isVerifyingAge = () =>
    validation.errors.fechaNacimiento === errors.verifyAge;
  const removeFechaValidation = () => (validation.errors.fechaNacimiento = '');

  // callValues by useEffect, is for await ipData before call stepOneA
  useEffect(() => {
    getEnum(dataset.token, '/personas/get-all/sexo', setGeneros);
    carScoringAPI
      .getCarScoreUtils(dataset.token)
      .then((res) => {
        dispatch({
          type: 'step/car-utils',
          payload: {
            cotizacionUSD: res.data.cotizacionUSD,
            iva: res.data.iva,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: 'show/error',
          payload: {
            errorTitle: 'Sin ofertas',
            errorBody: dataset.sinOfertasText,
          },
        });
      });

    carScoringAPI
      .getCarScoreUsages(dataset.token)
      .then((res) => {
        dispatch({
          type: 'step/car-get-usages',
          payload: {
            alreadyUsed: res.data.alreadyUsed,
            toUse: res.data.toUse,
            monthlyUse: res.data.monthlyUse,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: 'show/error',
          payload: {
            errorTitle: 'Sin ofertas',
            errorBody: dataset.sinOfertasText,
          },
        });
      });
    // eslint-disable-next-line
  }, []);

  async function handleSubmit(values) {
    if (values.cedula === '' || values.telefono === '') {
      dispatch(formTriesActions.addTry());
      setErrorText('Complete los datos');
      return setOpenAlert(true);
    }
    if (
      validarNumeroEspanol(values.montoAFinanciarUSD) ||
      validarNumeroEspanol(values.valorUSD) ||
      validarNumeroEspanol(values.ingresos)
    ) {
      setErrorText('Error en el ingreso de datos');
      setOpenAlert(true);
      return;
    }

    setOpenAlert(false);
    dispatch({ type: 'show/spinner' });
    setCallValues(values);
    return handleExecuteStepCall(values);
  }

  async function handleExecuteStepCall(values) {
    setCallValues(null);
    dispatch({
      type: 'step/car-info',
      payload: {
        montoAFinanciarUSD: transformarNumeroEspanol(values.montoAFinanciarUSD),
        valorUSD: transformarNumeroEspanol(values.valorUSD),
      },
    });
    return stepScoringA(
      clearCedula(values.cedula),
      values.telefono,
      dataset.canal,
      dataset.sucursal,
      dataset.token,
      ipData,
      values.genero,
      values.fechaNacimiento,
      transformarNumeroEspanol(values.ingresos)
    )
      .then(async (response) => {
        // if (!response.data.score) {
        //   console.log('174 SCORE A');
        //   dispatch({
        //     type: "show/error",
        //     payload: {
        //       errorTitle: "Sin ofertas",
        //       errorBody: dataset.sinOfertasText,
        //     },
        //   });
        // } else {
        dispatch({
          type: 'step/car-score',
          payload: Number(response.data.score),
        });
        // }
        dispatch({ type: 'show/spinner' });
        if (activeStep === maxStep) {
          handleMainNextStep();
        } else {
          handleNextStep();
        }
        setTimeout(() => {
          dispatch({ type: 'hide/spinner' });
        }, 4500);
      })
      .catch((error) => {
        dispatch({ type: 'hide/spinner' });
        dispatch(formTriesActions.addTry());
        if (showEmotions(dataset.sucursal)) {
          try {
            const { returnCode } = error.response.data;
            const { data } = error.response.data;
            if (
              (returnCode && returnCode.includes('Solicitud.no_offers')) ||
              (data.returnCode &&
                data.returnCode.includes('Solicitud.no_offers'))
            ) {
              dispatch({
                type: 'show/error',
                payload: {
                  errorTitle: 'Sin ofertas',
                  errorBody: dataset.sinOfertasText,
                },
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      });
  }

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gap={4}
        onSubmit={validation.handleSubmit}
      >
        <Stack spacing={4} direction={{ xs: 'column', sm: 'row', md: 'row' }}>
          <FormControl required sx={{ width: '100%' }}>
            <TextInput
              id="valorUSD"
              name="valorUSD"
              label="Valor Auto USD"
              required
              value={validation.values.valorUSD}
              onChange={validation.handleChange}
              error={
                validation.touched.valorUSD &&
                Boolean(validation.errors.valorUSD)
              }
            />
            <FormHelperText sx={{ color: 'red' }}>
              {validation.touched.valorUSD && validation.errors.valorUSD}
            </FormHelperText>
          </FormControl>
          <FormControl required sx={{ width: '100%' }}>
            <TextInput
              id="montoAFinanciarUSD"
              name="montoAFinanciarUSD"
              label="Monto USD de financiación tentativo"
              required
              value={validation.values.montoAFinanciarUSD}
              onChange={validation.handleChange}
              error={
                validation.touched.montoAFinanciarUSD &&
                Boolean(validation.errors.montoAFinanciarUSD)
              }
            />
            <FormHelperText sx={{ color: 'red' }}>
              {validation.touched.montoAFinanciarUSD &&
                validation.errors.montoAFinanciarUSD}
            </FormHelperText>
          </FormControl>
        </Stack>
        <Stack spacing={4} direction={{ xs: 'column', sm: 'row', md: 'row' }}>
          <FormControl required sx={{ width: '100%' }}>
            <TextInput
              id="cedula"
              name="cedula"
              label="Documento"
              required
              value={validation.values.cedula}
              onChange={(ev) => {
                ev.target.value = ev.target.value.trim();
                validation.handleChange(ev);
              }}
              error={
                validation.touched.cedula && Boolean(validation.errors.cedula)
              }
            />
            <FormHelperText
              error={
                validation.touched.cedula && Boolean(validation.errors.cedula)
              }
            >
              {validation.touched.cedula && validation.errors.cedula}
            </FormHelperText>
          </FormControl>
          <FormControl required sx={{ width: '100%' }}>
            <TextInput
              id="telefono"
              name="telefono"
              label="Celular"
              required
              value={validation.values.telefono}
              onChange={(ev) => {
                ev.target.value = ev.target.value.trim();
                validation.handleChange(ev);
              }}
              error={
                validation.touched.telefono &&
                Boolean(validation.errors.telefono)
              }
            />
            <FormHelperText sx={{ color: 'red' }}>
              {validation.touched.telefono && validation.errors.telefono}
            </FormHelperText>
          </FormControl>
        </Stack>
        <Stack spacing={4} direction={{ xs: 'column', sm: 'row', md: 'row' }}>
          <FormControl required sx={{ width: '100%' }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateInput
                label="Fecha de Nacimiento"
                required
                max={getSubstractYearsToToday(minYearsAgo)}
                min={getSubstractYearsToToday(maxYearsAgo)}
                value={validation.values.fechaNacimiento}
                onChange={(e) => {
                  if (isVerifyingAge()) removeFechaValidation();
                  validation.setFieldValue('fechaNacimiento', e);
                }}
                error={
                  validation.touched.fechaNacimiento &&
                  Boolean(validation.errors.fechaNacimiento)
                }
              />
            </LocalizationProvider>
            <FormHelperText sx={{ color: 'red' }}>
              {validation.touched.fechaNacimiento &&
                validation.errors.fechaNacimiento}
            </FormHelperText>
          </FormControl>
          <FormControl required sx={{ width: '100%' }}>
            <AutoComplete
              select
              label="Género"
              id="genero"
              required
              fullWidth
              name="genero"
              value={generos.length ? validation.values.genero : ''}
              onChange={validation.handleChange}
              error={
                validation.touched.genero && Boolean(validation.errors.genero)
              }
              items={generos.map((genero, index) => ({
                value: genero.value,
                label: capitalize(genero.value.replace('_', ' ')),
              }))}
            />

            <FormHelperText sx={{ color: 'red' }}>
              {validation.touched.genero && validation.errors.genero}
            </FormHelperText>
          </FormControl>
          <FormControl required sx={{ width: '100%' }}>
            <TextInput
              id="ingresos"
              name="ingresos"
              label="Ingresos mensuales"
              required
              value={validation.values.ingresos}
              onChange={validation.handleChange}
              error={
                validation.touched.ingresos &&
                Boolean(validation.errors.ingresos)
              }
            />
            <FormHelperText sx={{ color: 'red' }}>
              {validation.touched.ingresos && validation.errors.ingresos}
            </FormHelperText>
          </FormControl>
        </Stack>

        {/* <FormGroup required>
          <FormControlLabel
            value={validation.values.pyc}
            checked={validation.values.pyc}
            onChange={validation.handleChange}
            control={
              <Checkbox
                style={{ color: theme.palette.primary.checkColor }}
                id="pyc"
                name="pyc"
                required
              />
            }
            // onClick={handleClickOpen}
            label={
              <Typography fontSize="small" style={{ display: 'inline' }}>
                <a
                  style={{ color: '#000000' }}
                  href={dataset.urlTerminos}
                  target="_blank"
                  rel="noreferrer"
                >
                  Acepto términos y condiciones
                </a>
              </Typography>
            }
          />
          <FormHelperText
            error={validation.touched.pyc && Boolean(validation.errors.pyc)}
          >
            {validation.touched.pyc && validation.errors.pyc}
          </FormHelperText>
        </FormGroup> */}

        <Button
          variant="contained"
          type="submit"
          sx={{ width: 'min-content', alignSelf: 'flex-end' }}
          color="button"
        >
          Continuar
        </Button>
        <Error text={errorText} show={openAlert} setShow={setOpenAlert} />
      </Box>
    </>
  );
};

export default StepScoringA;
