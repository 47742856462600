import { isStaging } from "./rootConf";

function is() {
  return window.location.hostname.includes("vinan");
}

function updateState(state) {
  state.dataset.icon = "VINAN";
  state.dataset.main_icon_width = "200px";
  // state.dataset.subject_flavor = " ";
  state.dataset.texto_flavor = "Aprobá YA tu crédito, sin papeles";
  state.dataset.nombre_flavor = "Solicitudes | Vinan";
  state.dataset.apodo_flavor = "Vinan";
  state.dataset.operador = false;
  state.dataset.recaptcha = false;
  state.dataset.urlTerminos = "./assets/tyc/vinan_tyc_general.pdf";
  state.dataset.navbarLogo = "./assets/images/vinan/logo_navbar.webp";
  state.dataset.sinOfertasText =
    "Lo siento. En este momento no tenemos una oferta para vos.";
  state.displayNavbar = true;
  state.displayNavbarOptions = false;
  state.displaySide = false;
  state.side = false;
  state.dataset.myAccount = isStaging()
    ? "https://staging-micuenta.vinan.com.uy"
    : "https://micuenta.vinan.com.uy";
  state.dataset.externalLink = "";

  state.dataset.sucursal = 1;
  state.dataset.canal = "PASARELA";
}

export default {
  is,
  updateState,
};
