function is() {
  return window.location.hostname.includes("scorecar");
}

function updateState(state) {
  state.dataset.icon = "SCORECAR";
  state.dataset.texto_flavor = "";
  state.dataset.nombre_flavor = "scorecar";
  state.dataset.apodo_flavor = "scorecar";
  state.dataset.operador = true;
  state.dataset.recaptcha = false;
  state.dataset.urlTerminos =
    "https://facerecognitionprosbn.blob.core.windows.net/fr-prosbn/tyc.pdf";
  state.dataset.sinOfertasText =
    "Lo siento. En este momento no tenemos una oferta para vos.";
  state.displayNavbar = false;
  state.displayNavbarOptions = false;
  state.displaySide = false;
  state.side = false;
  state.dataset.myAccount = "";
  state.dataset.externalLink = "";
  state.dataset.showScoring = true;
  state.dataset.subject_flavor = "";
  state.dataset.shadow = true;
  state.dataset.hide_flavor_text = true;
  state.dataset.loginMinified=true;
  state.step = {
    showMobileValidation: true,
    showFaceRecognition: true,
    showIdValidator: true,
    showEmailValidation: true,
    showViaFinanciera: true,
    step1Text: "Ingreso de Usuario",
    title: "", // Si existe, sobreescribe el title del stepper(step1Text, step2Text, etc...)
  };
    state.dataset.backgroundUrl =
    "./assets/backgrounds/scorecar_background.webp";
}

export default {
  is,
  updateState,
};
